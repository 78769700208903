<template>
  <div class="cs-container">
    <el-dialog :visible.sync="isOpenModal" fullscreen center title="Danh sách sản phẩm">
      <template slot="title">
        <div class="fs-20 font-bold uppercase">{{ $t('Danh sách sản phẩm') }}</div>
      </template>
      <div v-loading="isLoading">
        <FilterProducts
          @onChangeFilter="handleChangeFilter"
          @onLoading="(loading)=>{isLoading=loading}"
        />
        <div class="flex justify-end items-center mb-1 mt-4">
          <el-button
            type="primary"
            :disabled="isLoading"
            @click="handleSelectedAll"
            class="text-white"
            size="medium"
          >{{ $t('Chọn tất cả') }}</el-button>
        </div>
        <div>
          <el-table
            ref="multipleTable"
            :data="listProducts"
            style="width: 100%"
            border
            row-key="id"
            @selection-change="handleUpdateSelectedProducts"
          >
            <el-table-column reserve-selection type="selection"></el-table-column>
            <el-table-column min-width="300" property="name" :label="$t('Tên sản phẩm')"></el-table-column>
            <el-table-column property="sku" :label="$t('Số đăng ký')"></el-table-column>
            <el-table-column property="ecom_dosage_form.name" :label="$t('Dạng bào chế')"></el-table-column>
            <el-table-column property="ecom_specification.name" :label="$t('Quy cách đóng gói')"></el-table-column>
            <el-table-column property="ecom_manufacturer.name" :label="$t('Nhà sản xuất')"></el-table-column>
            <el-table-column property="ecom_brand.name" :label="$t('Thương hiệu')"></el-table-column>
          </el-table>
          <AppPagination
            :paginationProps="pagination"
            @onChangePagination="handleChangePagination"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="flex justify-end items-center gap-2">
          <el-button @click="isOpenModal = false">{{ $t('Huỷ') }}</el-button>
          <el-button
            type="primary"
            @click="isOpenModal = false"
            :disabled="isLoading"
            class="text-white"
          >{{ $t('Xác nhận') }}</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { AppPagination } from '../../../components/Common'
import FilterProducts from './FilterProducts.vue'

export default {
  name: 'ModalListMkProducts',
  components: { FilterProducts, AppPagination },
  data () {
    return {
      isOpenModal: false,
      isLoading: false,
      listProducts: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      },
      filterParams: {}
    }
  },
  created () {},
  methods: {
    async handleGetMarketplaceProducts (paramsData) {
      if (!this.$globalOrg?.id) return

      try {
        this.isLoading = true
        const params = {
          page_size: paramsData?.pageSize || this.pagination.pageSize,
          page_num: paramsData?.currentPage || this.pagination.currentPage,
          sort_by: 'created_at',
          order: 'desc',
          keyword: paramsData?.searchValue,
          org_id: this.$globalOrg.id,
          // supplier_id: store.state.authenticate.userLogin?.supplierInfo?.id,
          ecom_brand_id: paramsData?.brandId,
          origin_id: paramsData?.originId,
          manufacturer_id: paramsData?.manufacturerId,
          dosage_form_id: paramsData?.dosageId,
          specification_id: paramsData?.packingId,
          ecom_unit_id: paramsData?.unitId
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getMarketplaceProducts(params)
        this.listProducts = response.data?.data || []
        this.pagination = {
          currentPage: response.data?.page?.page_num || 1,
          pageSize: response.data?.page?.page_size || 10,
          totalItems: response.data?.page.total || 0
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleOpenModal (isOpen) {
      this.isOpenModal = isOpen
      if (isOpen) {
        this.handleGetMarketplaceProducts()
      }
    },
    handleUpdateSelectedProducts (data) {
      this.$emit('onChangeSelectedProducts', data)
    },
    handleChangeFilter (data) {
      this.filterParams = data

      const params = {
        currentPage: 1,
        ...data
      }

      this.handleGetMarketplaceProducts(params)
    },
    handleSelectedAll () {
      this.$emit('onSelectedAll')
      this.isOpenModal = false
    },
    handleChangePagination (data) {
      const params = {
        ...this.filterParams,
        ...data
      }
      this.handleGetMarketplaceProducts(params)
    }
  }
}
</script>

<style lang="scss" scoped>
.cs-container * {
  color: black;
}

::v-deep {
  thead * {
    color: black !important;
  }
  .el-input__inner {
    border: 1px solid #dcdfe6 !important;
    background-color: white !important;
  }
}
</style>